import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Title, ButtonIcon } from "../../components/Core";
import Availability from "../../components/Availability";
import bgFooter from "../../assets/image/png/subscribe-pattern.png";

const SectionStyled = styled(Section)`
  &::before {
    position: absolute;
    bottom: -150px;
    content: "";
    width: 120%;
    height: 150%;
    background: url(${bgFooter}) bottom center no-repeat;
    background-size: cover;
    z-index: -1;
  }
`;

const Hero = () => {
  return (
    <>
      <SectionStyled className="position-relative">
        <Container>
          <Row className="text-center justify-content-center">
            <Col lg="11" xl="8">
              <Availability />
              <div className="text-center my-5">
                <Title>Creativity + Innovation with Boom!</Title>
              </div>
              <div className="text-center">
                <Link className="nav-link" role="button" aria-expanded="false" to={`/contact`}>
                  <ButtonIcon>
                    Let's Talk Now
                  </ButtonIcon>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Hero;
